<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: [
        { name: '品牌名称', type: 'text', fieldName: 'keyword', value: '' },
        { name: '类型', type: 'select', fieldName: 'type', value: '', constantDict: 'brand_type' },
        {
          fieldName: 'cate_id',
          type: 'treeSelect',
          name: '品类',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/cate/list',
            value: 'id',
            label: 'name',
            params: { type: 1 },
          },
        },
        {
          name: '粒子显示',
          type: 'select',
          fieldName: 'particle_show',
          value: '',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
      ],
      config: {
        url: '/brand/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            this.$set(el, 'attachment_id', Array.isArray(el.attachment) ? [] : [el.attachment]);
            this.$set(el, 'thumb_id', Array.isArray(el.thumb) ? [] : [el.thumb]);
            this.$set(el, 'large_id', Array.isArray(el.large) ? [] : [el.large]);
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '名称', prop: 'name' },
        { name: '类型', prop: 'type_name' },
        {
          name: '是否显示',
          func: (ops) => (ops.status ? '否' : '是'),
        },
        {
          name: '粒子显示',
          func: (ops) => (ops.particle_show ? '是' : '否'),
        },
        // {
        //   name: '前台显示',
        //   func: (ops) => (ops.is_show ? '是' : '否'),
        // },
        { name: '排序', prop: 'sort' },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        { fieldName: 'name', type: 'text', name: '名称', value: '', required: true },
        { fieldName: 'type', type: 'select', name: '类型', value: '', required: true, constantDict: 'brand_type' },
        { fieldName: 'sort', type: 'number', name: '排序', value: '', required: false },
        {
          fieldName: 'status',
          type: 'select',
          name: '状态',
          required: true,
          value: '',
          options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 },
          ],
        },
        {
          fieldName: 'particle_show',
          type: 'select',
          name: '粒子显示',
          required: true,
          value: '',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        // {
        //   fieldName: 'is_show',
        //   type: 'select',
        //   name: '前台显示',
        //   required: true,
        //   value: '',
        //   options: [
        //     { label: '是', value: 1 },
        //     { label: '否', value: 0 },
        //   ],
        // },
        {
          fieldName: 'cate_ids',
          type: 'treeSelect',
          name: '上级分类',
          value: [],
          options: [],
          required: true,
          remote: true,
          width: '100%',
          collapseTags: false,
          clickTreeTop: true,
          checkStrictly: true,
          props: {
            url: '/cate/list',
            value: 'id',
            label: 'name',
            params: { type: 1 },
          },
        },
        { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
        // {
        //   fieldName: 'attachment_id',
        //   type: 'upload',
        //   name: '图片',
        //   value: [],
        //   multiple: false,
        //   required: false,
        //   width: '100%',
        // },
        {
          fieldName: 'thumb_id',
          type: 'upload',
          name: '缩略图',
          value: [],
          multiple: false,
          required: false,
          width: '100%',
        },
        {
          fieldName: 'large_id',
          type: 'upload',
          name: '大图(1920*890)',
          value: [],
          multiple: false,
          required: false,
          width: '100%',
        },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
    };
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/brand/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const response = await this.$baseHttp.post('/brand/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
